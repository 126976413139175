import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import DialogDefault from "@/components/Content/Dialogs/Default.vue";
import { DialogDeleteType } from "@/interfaces/event";
import { Getter } from "vuex-class";
import { EventEntity } from "@/models/events/Event";

@Component({
	components: { DialogDefault },
	methods: {},
})
export default class DialogDelete extends Vue {
	@Prop({ required: true }) public open: Boolean = false;
	@Prop({ default: false }) public loading!: Boolean;
	@Prop({ default: null }) public message!: string;
	@Prop({ default: null }) public data!: any;
	@Prop({ default: null }) public dataType!: DialogDeleteType;
	@Getter("events/storeEvent") readonly storeEvent!: EventEntity;

	@Emit("action")
	public actionRemove(event: string) {
		return { event, dataType: this.dataType };
	}

	public get getEventName() {
		return this.storeEvent.name;
	}

	public get getMessage() {
		return this.$t(`${this.message}`, {
			event_name: this.getEventName,
			associate_name: this.data?.association?.name,
		});
	}

	private created() {
		this.$nextTick(() => {});
	}

	private mounted() {}

	public handleCancel() {
		this.actionRemove("cancel");
	}

	public handleConfirm() {
		this.actionRemove("confirm");
	}
}
