import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import Elegible from "@/views/Admin/Event/Dependencies/Elegible/index.vue";
import { TabItemEntity } from "@/models/Core/Tab";
import { EventEntity } from "@/models/events/Event";
import { TableAssociatedDataEntity } from "@/models/events/Table";
import { ConfigFields, ValidationRules } from "@/models/events/RuleBuilder";
import {
	AssociatedItem,
	EVENT_ASSOCIATION_TYPE,
	EventField,
	ResourceItem,
	TYPE_FETCH,
} from "@/interfaces/event";
import { TypeLoading } from "@/interfaces/loading";
import { EventMixin } from "@/views/Admin/Event/Dependencies/Mixins/EventMixin";
import DispatchMixin from "@/mixins/Dispatch";
import { ROUTE_DISPATCH } from "@/models/events/Data";
import { DataFieldEntity } from "@/models/events/Field";
import {
	AttributionType,
	findAttributionByType,
	getTabItems,
	isAttributionElegible,
} from "@/models/events/Tab";

@Component({
	components: { Elegible },
	mixins: [EventMixin, DispatchMixin],
})
export default class EventCreate extends Vue {
	public redirectTo!: string;
	public currentTab!: number;
	public items: TabItemEntity[] = getTabItems(true);
	public rules!: ValidationRules;
	public configFields!: ConfigFields;

	public get getFilteredItems() {
		return this.items.map((i) => {
			if (i.key === 1) {
				i.disabled = !this.isEnableTabElegible;
			}
			return i;
		});
	}

	@Getter("events/storeEvent") getEvent!: EventEntity;
	@Getter("events/getTables") tables!: {
		campaigns: TableAssociatedDataEntity;
		line_items: TableAssociatedDataEntity;
	};

	@Mutation("events/SET_EVENT") setEvent!: (event: EventEntity) => void;
	@Mutation("events/SET_TABLE_ITEMS") setItems!: (payload: {
		key: string;
		items: AssociatedItem[];
	}) => void;

	@Getter("events/storeDataFields") dataFields!: DataFieldEntity;

	public handleTabIndex!: (tabIndex: number, eventId?: number | null) => void;
	public handleTabChange(tabIndex: number) {
		this.handleTabIndex(tabIndex, this.getEvent.id);
	}
	public currentTabSelected!: (key: number) => boolean;
	private setLoadingData!: (actionType?: TypeLoading) => Promise<void>;
	public dispatchStore!: <T>(moduleFunc: string, data: T) => Promise<any>;
	public onEventDataChange!: (newVal: string, oldVal: string) => void;
	public handleUpdate!: (params: {
		type: string;
		key: string;
		value: any;
	}) => void;

	public handleChange<T>(params: {
		key: string;
		value: ResourceItem[];
		selected: number | null;
		fetch: Boolean;
	}): void {
		if (params.key === "event_attribution_id") {
			if (params.fetch) {
				this.initializeEventData();
				this.configFields.event_tag_type_id.required = false;
			} else {

			}
		}
	}

	async handleAction(params: { type: TYPE_FETCH }) {
		if (params.type === TYPE_FETCH.FETCH) {
			this.initializeEditData();
		}
	}

	private async initializeEditData(loading?: TypeLoading): Promise<void> {
		try {
			await this.setLoadingData(loading);
			await this.initializeEventData();
		} catch (error) {
		} finally {
			await this.setLoadingData();
		}
	}

	private updateRouteQuery() {
		if (this.isEnableTabElegible && this.isQueryElegible) {
			this.handleTabIndex(this.getTabElegible, this.getEvent.id);
		}
	}

	/**
	 * Determina si la pestaña es elegible para ser habilitada en base a la atribución del evento.
	 * @returns {boolean} Verdadero si la pestaña es elegible, falso en caso contrario.
	 */
	public get isEnableTabElegible(): boolean {
		const eventAttribution = this.getEvent?.event_attribution;
		return isAttributionElegible(eventAttribution);
	}

	public isElegibleById(items: ResourceItem[], id: number | null): boolean {
		if (!id) return false;
		const ATTRIBUTION = findAttributionByType(
			items,
			AttributionType.WHITELIST_TEXT
		);
		return ATTRIBUTION?.id === id;
	}

	public get isQueryElegible() {
		const tabValue = Number(this.$route.query.tab);
		return !isNaN(tabValue) && tabValue === 1;
	}

	public get getTabElegible() {
		const tabValue = Number(this.$route.query.tab);
		return tabValue;
	}

	private async initializeEventData(): Promise<void> {
		const event: EventField = await this.dispatchStore(
			ROUTE_DISPATCH.eventShow,
			this.$route.params.id
		);
		this.setEvent(new EventEntity(event));
		this.initializeTableData();
	}

	private initializeTableData(): void {
		const campaignItems = this.mapCampaignItems();
		this.setItems({
			key: EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID,
			items: campaignItems,
		});

		const lineItemItems = this.mapLineItemItems();
		this.setItems({
			key: EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID,
			items: lineItemItems,
		});
	}

	private mapCampaignItems(): AssociatedItem[] {
		return this.getEvent.campaigns || [];
	}

	private mapLineItemItems(): AssociatedItem[] {
		return this.getEvent.line_items || [];
	}

	// Hooks de ciclo de vida de Vue
	private async created(): Promise<void> {
		// Código para hook created
		this.$nextTick(() => {
			this.dataFields = new DataFieldEntity();
			//this.updateRouteQuery();
		});
	}

	private mounted() {
		this.$nextTick(() => {
			this.initializeEditData(TypeLoading.loading);
			//this.updateRouteQuery();
		});
	}

	private beforeDestroy() {
		this.dataFields.tag.setData("generated", "");
	}

	private destroyed() {
		this.dataFields.tag.setData("generated", "");
	}

	// Watchers
	@Watch("$route.query", { immediate: true, deep: true })
	onQueryChange(newQuery: any, oldQuery: any) {
		const newQueryTab = newQuery?.tab;
		const oldQueryTab = oldQuery?.tab;
		if (newQueryTab === oldQueryTab) return;
		this.updateRouteQuery();
	}
	@Watch("getEvent", { immediate: true, deep: true })
	onChangeEvent(newQuery: any, oldQuery: any) {
		this.updateRouteQuery();
	}
}
