import {
	AssociatedItem,
	DialogDelete,
	DialogDeleteData,
	DialogDeleteType,
} from "@/interfaces/event";
import { EventEntity } from "./Event";

export class DialogDeleteEntity implements DialogDelete {
	type: DialogDeleteType = DialogDeleteType.SINGLE;
	loading: Boolean = false;
	open: Boolean = false;
	data: DialogDeleteData = new DialogDeleteDataEntity();
	message: string = "";

	constructor(parameters?: DialogDelete) {
		this.type = parameters?.type || DialogDeleteType.SINGLE;
		this.loading = parameters?.loading || false;
		this.open = parameters?.open || false;
		this.data = parameters?.data || new DialogDeleteDataEntity();
		this.message = parameters?.message || "";
	}

	setOpen(open: Boolean) {
		this.open = open;
	}

	setLoading(loading: Boolean) {
		this.loading = loading;
	}

	setData(data: DialogDeleteData) {
		this.data = data;
	}

	setType(type: DialogDeleteType) {
		this.type = type;
	}

	setMessage(message: string) {
		this.message = message;
	}

	setSelected(selected: AssociatedItem[] = []) {
		this.data.selected = selected;
		this.data.association = undefined;
	}

	setAssociation(association: AssociatedItem) {
		this.data.association = association;
        this.data.selected = undefined;
	}
}

export class DialogDeleteDataEntity implements DialogDeleteData {
	association?: AssociatedItem;
	event?: EventEntity;
	selected?: AssociatedItem[];
}
