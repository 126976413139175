import { SortingOption } from "@/interfaces/paginated";
import { isBoolean, isNumber } from "lodash";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
	components: {},
	methods: {},
})
export default class TableListFilters extends Vue {
	@Prop({ required: true }) private filters!: any;
	@Prop({ required: true }) private options!: SortingOption;

	public get hasFilters() {
		return Object.entries(this.filters).some(
			([key, filterValue]) => filterValue !== ""
		);
	}
	public get getFilters() {
		return this.filters;
	}
	public get getOptions() {
		return this.options;
	}
	public showChip(key: any, value: any) {
		if (typeof value === "boolean") return true;
		return value;
	}
	public getValue(value: any) {
		if (typeof value === "boolean") return `common.fields.${value ? 'active' : 'inactive'}`;
		return value;
	}

	@Emit("remove")
	public removeFilter(key: any) {
		return key;
	}
}
