import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import FormAssociation from "@/views/Admin/Event/Dependencies/EventAssociation/Form/index.vue";
import TableList from "@/views/Admin/Event/Dependencies/Table/index.vue";
import { mapActions } from "vuex";
import { ConfigDirectiveFieldEntity } from "@/models/events/Field";
import {
	AddItemsPayload,
	AssociatedItem,
	AsyncSetItemsPayload,
	CampaignPayload,
	DialogDeleteType,
	EVENT_ASSOCIATION_TYPE,
	LineItemPayload,
	TYPE_FETCH,
} from "@/interfaces/event";
import { getPayloads } from "@/models/events/Data";
import { EventEntity } from "@/models/events/Event";
import { TableAssociatedDataEntity } from "@/models/events/Table";
import { DialogDeleteEntity } from "@/models/events/Dialog";

@Component({
	components: { FormAssociation, TableList },
	methods: {
		...mapActions("campaign", { fetchCampaignList: "all" }),
		...mapActions("line_item", { fetchLineItemList: "all" }),
	},
})
export default class EventAssociation extends Vue {
	@Prop({ required: true }) public formConfig!: ConfigDirectiveFieldEntity;
	@Prop({ required: true }) public actionConfig!: ConfigDirectiveFieldEntity;
	@Prop({ required: true }) public type!: EVENT_ASSOCIATION_TYPE;
	@Prop({ required: true }) protected event_id!: number;
	@Prop({ required: true }) protected advertiser_id!: number;

	@Getter("events/storeEvent") getEvent!: EventEntity;
	@Getter("events/getTables") tables!: {
		campaigns: TableAssociatedDataEntity;
		line_items: TableAssociatedDataEntity;
	};
	@Getter("events/getForms") getForms!: { [x: string]: AssociatedItem[] };

	@Mutation("events/SET_TABLE_ITEMS") setItems!: (payload: {
		key: EVENT_ASSOCIATION_TYPE;
		items: AssociatedItem[];
	}) => void;

	public formLoading: Boolean = false;

	get filteredFormItems() {
		const tableItemIds = new Set(
			this.getTable.items.map((item) => item.id)
		);
		return this.getForms[this.type].filter(
			(item) => !tableItemIds.has(item.id)
		);
	}

	get getEventId() {
		return this.event_id;
	}

	get getAdvertiserId() {
		return this.advertiser_id;
	}

	get getTable() {
		return this.tables[this.type];
	}

	public setLoading(loading: Boolean = false) {
		this.formLoading = loading;
	}

	public fetchCampaignList!: (payload: CampaignPayload) => Promise<any[]>;
	public fetchLineItemList!: (payload: LineItemPayload) => Promise<any[]>;

	@Emit("asyncSetItems")
	public async handleAsyncSetItems(payload: AsyncSetItemsPayload) {
		return payload;
	}

	/**
	 * Quitar un elemento de la tabla
	 */
	public async handleDeleteItem({ item: { id } }: { item: { id: number } }) {
		const items = this.getTable.items.filter((item) => item.id !== id);
		this.setItems({ key: this.type, items });
	}

	/**
	 * Quitar los elementos seleccionados de la tabla
	 */
	public async handleDeleteSelected({
		selected,
	}: {
		selected: AssociatedItem[];
	}) {
		const items = this.getTable.items.filter((item) =>
			!selected.some((s) => s.id === item.id)
		);
		this.setItems({ key: this.type, items });
	}

	public handleRemove(params: {
		type: EVENT_ASSOCIATION_TYPE;
		dialogDeleteData: DialogDeleteEntity;
		dataType: DialogDeleteType;
	}) {
		if (params.dataType === DialogDeleteType.SINGLE) {
			if (params.dialogDeleteData.data.association) {
				this.handleDeleteItem({
					item: params.dialogDeleteData.data.association,
				});
			}
		}

		if (params.dataType === DialogDeleteType.MULTIPLE) {
			if (params.dialogDeleteData.data.selected) {
				this.handleDeleteSelected({
					selected: params.dialogDeleteData.data.selected,
				});
			}
		}
	}

	public async addItemsToTable(payload: AddItemsPayload) {
		const newItems = payload.items.map((item) =>
			this.mapToAssociatedItem(payload.type, item)
		);
		const items = [...new Set([...this.getTable.items, ...newItems])];
		this.setItems({ key: this.type, items });
	}

	private mapToAssociatedItem(type: TYPE_FETCH, item: any): AssociatedItem {
		const associatedKey =
			type === "campaign_id"
				? "line_items_count"
				: "creative_associations_count";
		return { ...item, associated: item[associatedKey] || "-" };
	}

	public async handleAsyncFocus({ type }: { type: TYPE_FETCH }) {
		try {
			this.setLoading(true);
			const items = await this.fetchItemsByType(type);

			this.getForms[this.type] = items;
			this.setLoading(false);
			return { type, items };
		} catch (error) {
			console.error(`Error fetching ${type}: `, error);
			this.setLoading(false);
			return { type, items: [] };
		}
	}

	private async fetchItemsByType(type: TYPE_FETCH) {
		const payloads = getPayloads(this.getAdvertiserId);
		switch (type) {
			case TYPE_FETCH.CAMPAIGN_ID:
				return this.fetchCampaignList(payloads.campaign_id);
			case TYPE_FETCH.LINE_ITEM_ID:
				return this.fetchLineItemList(payloads.line_item_id);
			default:
				throw new Error(`Unrecognized type: ${type}`);
		}
	}
}
