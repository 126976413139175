import { Component, Prop, Vue } from "vue-property-decorator";
import { TypeLoading } from "@/interfaces/loading";

@Component({
	components: {},
})
export default class DispatchMixin extends Vue {

	public async setLoadingData(actionType?: TypeLoading): Promise<void> {
		await this.dispatchStore("loading/setLoadingData", actionType);
	}

	public async dispatchStore<T>(moduleFunc: string, data: T) {
		return await this.$store.dispatch(moduleFunc, data);
	}
}
