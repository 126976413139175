import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import EventAssociation from "@/views/Admin/Event/Dependencies/EventAssociation/index.vue";
import CardElegibleAction from "@/components/Content/CardAction.vue";
import { CardActionType } from "@/interfaces/action";
import {
	ASSOCIATION_ROUTE_TYPE,
	AssociatedItem,
	EVENT_ASSOCIATION_TYPE,
	TYPE_FETCH,
} from "@/interfaces/event";
import { TypeLoading } from "@/interfaces/loading";
import DispatchMixin from "@/mixins/Dispatch";
import { EventEntity } from "@/models/events/Event";
import { TableAssociatedDataEntity } from "@/models/events/Table";
import { getConfigFieldData } from "@/models/events/Data";
import { DataFieldEntity } from "@/models/events/Field";

@Component({
	components: { EventAssociation, CardElegibleAction },
	mixins: [DispatchMixin],
})
export default class Elegible extends Vue {
	@Prop({ required: true }) private event_id!: number;
	@Prop({ required: true }) private advertiser_id!: number;
	@Getter("proccess/isLoading") isLoading!: Boolean;
	@Getter("events/storeEvent") getEvent!: EventEntity;
	@Getter("events/getTables") tables!: Record<
		EVENT_ASSOCIATION_TYPE,
		TableAssociatedDataEntity
	>;

	@Getter("events/getMessages") messages!: Record<
		EVENT_ASSOCIATION_TYPE,
		any[]
	>;

	@Getter("events/getForms") getForms!: Record<string, AssociatedItem[]>;

	@Getter("events/getAction") actions!: Record<CardActionType, Boolean>;

	@Getter("events/storeDataFields") dataFields!: DataFieldEntity;

	@Mutation("events/ADD_MESSAGE") addMessage!: (payload: {
		key: EVENT_ASSOCIATION_TYPE;
		message: any;
	}) => void;

	@Mutation("events/SET_MESSAGES") setMessages!: (payload: {
		key: EVENT_ASSOCIATION_TYPE;
		messages: any[];
	}) => void;

	@Mutation("events/RESET_TABLE_ITEMS") resetTableData!: () => void;

	@Mutation("events/SET_ACTION") setActionDisabled!: (payload: {
		key: CardActionType;
		disabled: boolean;
	}) => void;

	@Emit("action")
	public async actionEmit() {
		return { type: TYPE_FETCH.FETCH };
	}

	public get getEventId() {
		return this.event_id;
	}

	public get getAdvertiserId() {
		return this.advertiser_id;
	}

	public get getShowActions() {
		let actions: string[] = ["save", "cancel"];
		return actions;
	}

	public get getTableTypes() {
		return EVENT_ASSOCIATION_TYPE;
	}

	public get configFieldCampaign() {
		return getConfigFieldData(TYPE_FETCH.CAMPAIGN_ID);
	}

	public get configFieldLineItem() {
		return getConfigFieldData(TYPE_FETCH.LINE_ITEM_ID);
	}

	private setLoadingData!: (actionType?: TypeLoading) => Promise<void>;

	public async handleAsyncSetItems({
		type,
		items,
	}: {
		type: string;
		items: AssociatedItem[];
	}): Promise<void> {
		this.getForms[type] = items;
		this.setActions(false);
	}

	public setActions(disabled: boolean) {
		this.setActionDisabled({ key: CardActionType.SAVE, disabled });
		// this.setActionDisabled({ key: CardActionType.CANCEL, disabled });
	}

	public async handleAction({ type }: { type: string }): Promise<void> {
		this.resetMessages();

		if (type === CardActionType.SAVE) {
			await this.associateAll();
			this.setActions(true);
		}

		if (type === CardActionType.CANCEL) {
			this.actionEmit();
			this.setActions(true);
			this.redirecTo("EventIndex");
		}
	}

	private redirecTo(name: string) {
		this.$router.push({ name });
	}

	private resetMessages() {
		this.setMessages({
			key: EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID,
			messages: [],
		});
		this.setMessages({
			key: EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID,
			messages: [],
		});
	}

	private async associateAll() {
		try {
			this.setLoadingData(TypeLoading.loading);
			const results = await Promise.allSettled([
				this.associateCampaigns(),
				this.associateLineItems(),
			]);
			this.processAssociationResults(results);
			this.setLoadingData();
		} catch (error) {
			console.error("Error in associateAll: ", error);
			this.setLoadingData();
		}
	}

	private async associateCampaigns() {
		return this.associateItems(
			EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID,
			ASSOCIATION_ROUTE_TYPE.CAMPAIGN_ID
		);
	}

	private async associateLineItems() {
		return this.associateItems(
			EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID,
			ASSOCIATION_ROUTE_TYPE.LINE_ITEM_ID
		);
	}

	private async associateItems(
		associationType: EVENT_ASSOCIATION_TYPE,
		routeType: ASSOCIATION_ROUTE_TYPE
	) {
		const payload = this.createAssociatePayload(associationType);
		return await this.$store.dispatch("events/associate", {
			route_type: routeType,
			payload,
		});
	}

	private createAssociatePayload(type: EVENT_ASSOCIATION_TYPE) {
		const keyMap = {
			[EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID]: "campaigns_ids",
			[EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID]: "line_items_ids",
		};
		return {
			event_id: this.getEventId,
			[keyMap[type]]: this.tables[type].items.map((item) =>
				Number(item.id)
			),
		};
	}

	private processAssociationResults(results: PromiseSettledResult<void>[]) {
		results.forEach((result, index) => {
			const messageType =
				index === 0
					? EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID
					: EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID;
			const associationMessage =
				index === 0
					? "events.response.campaign_id"
					: "events.response.line_item_id";

			const message =
				result.status === "fulfilled"
					? { type: "success", message: associationMessage }
					: { type: "error", message: result.reason };

			if (result.status === "fulfilled") {
				this.addMessage({ key: messageType, message });
			}

			setTimeout(() => this.clearMessages(messageType), 5000);
		});
	}

	private clearMessages(type: EVENT_ASSOCIATION_TYPE) {
		this.setMessages({ key: type, messages: [] });
	}
}
