import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import { CardActionType } from "@/interfaces/action";
import DialogDelete from "@/views/Admin/Event/Dependencies/Dialogs/Delete/index.vue";
import { EventEntity } from "@/models/events/Event";
import { DialogDeleteType, EVENT_ASSOCIATION_TYPE } from "@/interfaces/event";
import { DialogDeleteEntity } from "@/models/events/Dialog";
import Filterable from "@/components/Header/Tables/Filterable.vue";
import { getDefaultSortingOption } from "@/utils/filter-global";
import { SortingOption } from "@/interfaces/paginated";
import TableListFilters from "@/components/Header/Tables/Filters/index.vue";
import { TableFilterEntity } from "@/models/events/Table";
import { isNull } from "lodash";
@Component({
	components: { DialogDelete, Filterable, TableListFilters },
})
export default class TableList extends Vue {
	@Prop({ default: 10 }) readonly itemsPerPage!: number;
	@Prop({ required: true }) readonly associationType!: EVENT_ASSOCIATION_TYPE;
	@Prop({ default: () => [] }) readonly tableHeaders!: any[]; // Use specific types instead of any if possible
	@Prop({ default: () => [] }) readonly tableItems!: any[]; // Use specific types instead of any if possible

	public dialogDeleteData: DialogDeleteEntity = new DialogDeleteEntity();
	public options = getDefaultSortingOption();
	public filters = new TableFilterEntity();

	@Getter("events/storeEvent") readonly storeEvent!: EventEntity;
	@Mutation("events/SET_ACTION") setActionDisabled!: (payload: {
		key: CardActionType;
		disabled: boolean;
	}) => void;

	@Emit("remove")
	emitRemoveAction(removeParams: {
		dataType: DialogDeleteType;
		event: "cancel" | "confirm";
	}) {
		const payload = {
			associationType: this.associationType,
			dialogDeleteData: this.dialogDeleteData,
			...removeParams,
		};
		this.setActions(false);
		return payload;
	}

	public handleRemoveSelected() {
		this.dialogDeleteData.setType(DialogDeleteType.MULTIPLE);
		this.dialogDeleteData.setMessage(
			"events.dialogs.confirm_delete_selected_from_event"
		);
		this.dialogDeleteData.setOpen(true);
	}

	public handleRemoveItem(item: any) {
		this.dialogDeleteData.setType(DialogDeleteType.SINGLE);
		this.dialogDeleteData.setMessage(
			`events.dialogs.confirm_delete_from_event_${this.associationType}`
		);
		this.dialogDeleteData.setOpen(true);
		this.dialogDeleteData.setAssociation(item);
	}

	public setActions(disabled: boolean) {
		this.setActionDisabled({ key: CardActionType.SAVE, disabled });
		this.setActionDisabled({ key: CardActionType.CANCEL, disabled });
	}

	public async handleDeleteAction(deleteParams: {
		event: "cancel" | "confirm";
		dataType: DialogDeleteType;
	}) {
		if (deleteParams.event === "cancel") {
			this.dialogDeleteData = new DialogDeleteEntity();
		} else if (deleteParams.event === "confirm") {
			this.emitRemoveAction(deleteParams);
			this.dialogDeleteData = new DialogDeleteEntity();
		}
	}

	async setFilter(params: { key: string; value: any }) {
		this.filters = new TableFilterEntity();
		this.filters.setFilter(params.key, params.value);
	}

	async updateParams(params: { filters: any; options: SortingOption }) {
		this.filters.setFilters(params.filters);
		this.options = params.options;
	}

	public selectedOption(params: { options: SortingOption; filter: any }) {
		this.setFilter({ key: params.options.sort, value: params.filter });
		this.updateParams({
			filters: this.filters,
			options: params.options,
		});
	}

	public removeOption(event: any) {
		this.options = getDefaultSortingOption();
		this.filters.setFilters();
	}

	public removeFilter(key: string) {
		this.filters.setFilter(key, null);
	}

	public get hasFilters() {
		return Object.entries(this.filters).some(
			([key, filterValue]) => filterValue !== ""
		);
	}

	public get isDialogOpen() {
		return this.dialogDeleteData.open;
	}

	public get getTableHeaders() {
		return this.tableHeaders.map((t) => ({
			...t,
			text: this.$t(`events.${this.associationType}.${t.value}`),
		}));
	}

	public get filterItems() {
		let filteredItems = this.tableItems.filter((item) =>
			Object.entries(this.filters).every(([key, filterValue]) => {
				if (!filterValue) return true;
				if (key === "active") return item.key === filterValue;
				return String(item[key])
					.toLowerCase()
					.includes(filterValue?.toLowerCase());
			})
		);

		if (this.options.sort) {
			filteredItems.sort((a, b) =>
				this.sortItems(a, b, this.options.sort, this.options.order)
			);
		}

		return filteredItems;
	}

	private sortItems(
		a: any,
		b: any,
		sortKey: string,
		sortOrder: string
	): number {
		if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
		if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
		return 0;
	}

	getColor(isActive: boolean): string {
		return isActive ? "green" : "red";
	}

	getActiveStatusText(isActive: boolean): string {
		return isActive ? "common.fields.active" : "common.fields.inactive";
	}

	/**
     * Handler Redirect
     */
    handleRedirectTo(params: any){
		if(this.associationType == "campaigns"){
			const routeData = this.$router.resolve({name: "CampaignEdit", params: {id: params.id}});
			window.open(routeData.href, '_blank');
		}
		if(this.associationType == "line_items"){
			const routeData = this.$router.resolve({name: "LineItemEdit", params: {id: params.id}});
			window.open(routeData.href, '_blank');
		}
	}

	public get getDataSelected() {
		return this.dialogDeleteData?.data?.selected?.length || 0;
	}

	public get getRemoveSelectedText() {
		return `events.button.remove-selected-${
			this.getDataSelected > 1 ? "multiple" : "single"
		}`;
	}
}
