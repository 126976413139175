import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import { ConfigDirectiveFieldEntity } from "@/models/events/Field";
import { Mutation, Getter } from "vuex-class";
import { EVENT_ASSOCIATION_TYPE } from "@/interfaces/event";
import { CardActionType } from "@/interfaces/action";
import { isEmpty } from "lodash";
import { getPermisionEvent } from "@/utils/permissionResolve";

@Component({
	components: {
		CardAutocomplete,
	},
})
export default class FormAssociation extends Vue {
	/**
	 * Prop
	 */
	@Prop({ required: true }) public formConfig!: ConfigDirectiveFieldEntity;
	@Prop({ required: true }) public actionConfig!: ConfigDirectiveFieldEntity;
	@Prop({ required: true }) public formItems!: any[];
	@Prop({ default: false }) public loading!: Boolean;

	@Mutation("events/SET_MESSAGES") setMessages!: (payload: {
		key: EVENT_ASSOCIATION_TYPE;
		messages: any[];
	}) => void;

	@Mutation("events/SET_ACTION") setDisabledAction!: (payload: {
		key: CardActionType;
		disabled: Boolean;
	}) => void;

	@Getter("profile/getAbility") getAbility;

	// Declarar explícitamente las propiedades.
	public valid: boolean = false;
	public form = { field: [] as any[] };
	public rules: any[] = [];
	public selected_all: number[] = [];

	//Computed
	public get getPermission(){
		return getPermisionEvent();
	}

	public get canAssociation(){
		if(this.formConfig.ref == "campaign_id"){
			return this.getAbility.can(this.getPermission.actions['create_campaign_association'], this.getPermission.subject);
		}else if(this.formConfig.ref == "line_item_id"){
			return this.getAbility.can(this.getPermission.actions['create_line_item_association'], this.getPermission.subject);
		}else{
			return false;
		}
	}

	@Emit("asyncFocus")
	public async handleAsyncFocus() {
		return { type: this.formConfig.ref };
	}

	@Emit("asyncAddToTable")
	public async addToTable() {
		this.setDisabledAction({ key: CardActionType.SAVE, disabled: false });
		this.setDisabledAction({ key: CardActionType.CANCEL, disabled: false });
		const payload = { type: this.formConfig.ref, items: this.form.field };
		this.form.field = [];
		return payload;
	}

	public handleAllItems(event: number[]) {
		this.selected_all = event;
		this.form.field = isEmpty(event) ? [] : this.formItems;
	}

	private async emitEventAsync(
		eventName: string,
		payload: { field: number[] }
	): Promise<void> {
		return new Promise((resolve, reject) => {
			// Aquí emites el evento
			this.$emit(eventName, {
				payload,
				// Datos del evento
				success: resolve,
				error: reject,
			});
		});
	}

	public async resetMessages(): Promise<void> {
		this.setMessages({
			key: EVENT_ASSOCIATION_TYPE.CAMPAIGN_ID,
			messages: [],
		});
		this.setMessages({
			key: EVENT_ASSOCIATION_TYPE.LINE_ITEM_ID,
			messages: [],
		});
	}

	@Watch("form.field")
	private onFormFieldChanged(val: any[]): void {
		const isEqual = this.formItems.length === this.form.field.length;
		this.selected_all = isEqual ? [0] : [];
		this.resetMessages();
	}
}
